<template>
  <div
    class="crypkit-base-input"
    :class="{
      'validation-error': !!errorMessage,
      disabled: disabled,
    }"
  >
    <Label
      v-if="label !== ''"
      :label="label"
      :required="required"
      :hide-asterisk="hideAsterisk"
      :help="help"
    />
    <textarea
      :id="id"
      v-model="inputValue"
      :name="name"
      :disabled="disabled"
      :required="required"
      :maxlength="maxlength"
      :placeholder="placeholder"
      class="crypkit-textarea"
      @input="handleChange"
    />
    <!-- Input validation message -->
    <div v-if="!hideErrors" class="validation-messages">{{ errorMessage }}</div>
  </div>
</template>

<script setup lang="ts">
import './TextArea.scss'

import { type PropType, watch } from 'vue'
import { useField } from 'vee-validate'
import { v4 as uuidv4 } from 'uuid'
import Label from '@/components/misc/Label'
import labelProps from '@/components/misc/Label/props'

const props = defineProps({
  ...labelProps,
  id: {
    type: String,
    default: null,
  },
  modelValue: {
    type: String as PropType<string | null>,
    default: '',
    required: false,
  },
  name: {
    type: String,
    default: () => uuidv4(),
  },
  disabled: {
    type: Boolean,
    default: false,
    required: false,
  },
  maxlength: {
    type: String,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: '',
  },
  hideErrors: {
    type: Boolean,
    default: false,
  },
})

const model = defineModel<string>({
  default: '',
})

const { value: inputValue, errorMessage } = useField(props.name, undefined, {
  initialValue: model.value,
  validateOnValueUpdate: false,
})

const handleChange = () => {
  model.value = inputValue.value
}

watch(model, (value) => {
  if (inputValue.value !== value) {
    inputValue.value = value
  }
})
</script>
