import BreakpointsPlugin from './BreakpointsPlugin'
import { type App, reactive } from 'vue'

export default class Breakpoints {
  static install(app: App, options = {}): void {
    const state = reactive(new BreakpointsPlugin(options))
    app.config.globalProperties.$breakpoints = state
    app.config.globalProperties.$breakpoints.init()

    app.provide('breakpoints', state)
  }
}
