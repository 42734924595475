<template>
  <div
    class="crypkit-main-navbar"
    :class="{
      'is-scrolled': isScrolled || isSmallCurrenciesToggled,
      'bg-surface': isScrolled || isSmallCurrenciesToggled,
    }"
  >
    <div
      class="nav-container"
      :class="{ 'justify-between': $breakpoints.mdAndDown }"
    >
      <div class="block lg:hidden">
        <SvgIcon
          icon="Menu"
          class="cursor-pointer"
          size="lg"
          @click="showMobileSidebar"
        />
      </div>
      <div
        class="logo-container hidden lg:flex"
        :class="{ 'border-r border-outline': showTitle }"
      >
        <div key="big" class="logo-content">
          <router-link :to="{ name: homeRoute }">
            <transition mode="out-in" name="fade">
              <LogoFullWhite
                v-if="
                  settings.additional.theme == 'dark' ||
                  settings.additional.theme == 'black'
                "
                class="logo"
              />
              <LogoFull v-else class="logo" />
            </transition>
          </router-link>
        </div>
      </div>
      <div
        class="flex-shrink overflow-hidden pr-4"
        :class="showTitle ? 'flex-grow' : 'text-center'"
      >
        <h1 v-if="showTitle" class="mb-0">
          {{ title }}
        </h1>
        <router-link v-else :to="{ name: homeRoute }">
          <LogoIcon class="h-8 w-8 inline-block md:hidden" />
        </router-link>
      </div>
      <div
        class="flex items-center"
        :class="{ 'flex-grow': $breakpoints.lgAndUp }"
      >
        <Currencies v-if="$breakpoints.mdAndUp" class="mr-auto" />
        <SmallCurrencies
          v-else
          @click="isSmallCurrenciesToggled = !isSmallCurrenciesToggled"
        />
        <template v-if="$breakpoints.lgAndUp">
          <AccountSelection class="w-[218px]" />
          <div class="flex items-center">
            <TogglePrivacyMode class="mx-6" />
            <template v-if="hasPermission(239)">
              <Notification id="navigation-notifications" class="-ml-3 mr-3" />
            </template>
            <UserMenu menu-from-right />
          </div>
        </template>
      </div>
    </div>
    <div class="w-full flex justify-center sm:justify-end">
      <transition name="expand-currencies">
        <Currencies
          v-if="$breakpoints.smAndDown && isSmallCurrenciesToggled"
          class="mb-2 -mt-2"
        />
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { throttle } from 'lodash-es'

import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'

import LogoFull from '@/assets/images/LogoFull.svg?component'
import LogoFullWhite from '@/assets/images/LogoFullWhite.svg?component'
import LogoIcon from '@/assets/images/LogoIcon.svg?component'

import SvgIcon from '@/components/misc/SvgIcon'

import Currencies from './Currencies.vue'
import AccountSelection from '@/components/navigation/components/AccountSelection.vue'
import Notification from '@/components/navigation/components/Notifications.vue'
import SmallCurrencies from '@/components/navigation/components/SmallCurrencies.vue'
import TogglePrivacyMode from '@/components/navigation/components/TogglePrivacyMode.vue'
import UserMenu from '@/components/navigation/components/UserMenu.vue'

export default defineComponent({
  components: {
    AccountSelection,
    UserMenu,
    Currencies,
    Notification,
    SmallCurrencies,
    LogoFull,
    LogoFullWhite,
    LogoIcon,
    SvgIcon,
    TogglePrivacyMode,
  },

  data() {
    return {
      isScrolled: false,
      isSmallCurrenciesToggled: false,
      throttledScrollCheck: () => {},

      globalStore: useGlobalStore(),
    }
  },

  computed: {
    ...mapState(useGlobalStore, ['sidebarToggled', 'activeViewName']),
    ...mapState(useUserStore, [
      'currentView',
      'homeRoute',
      'hasPermission',
      'settings',
    ]),
    isAdminView(): boolean {
      return this.currentView === 'admin'
    },
    title(): string | null {
      return this.isAdminView ? this.activeViewName : null
    },
    showTitle(): boolean {
      return Boolean(this.isAdminView) && Boolean(this.title)
    },
  },

  created() {
    this.throttledScrollCheck = throttle(this.scrollCheck, 100)
    window.addEventListener('scroll', this.throttledScrollCheck)
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.throttledScrollCheck)
  },

  methods: {
    scrollCheck() {
      this.isScrolled = window.scrollY > 0
    },
    showMobileSidebar() {
      if (!this.sidebarToggled) {
        this.globalStore.toggleSidebar()
      }
      this.$bus.emit('showMobileSidebar')
    },
  },
})
</script>
