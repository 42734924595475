<template>
  <div>
    <Collapsible
      class="crypkit-navbar-notification"
      :direction="$breakpoints.mdAndDown ? 'left' : null"
      @closed="markAsRead"
    >
      <template #button>
        <div class="relative mx-3">
          <SvgIcon
            icon="Notification"
            class="text-secondary hover:text-brand"
          />
          <div v-show="total" class="notice" />
        </div>
      </template>
      <template #body>
        <div>
          <div class="flex justify-between items-center -mt-2 mb-2">
            <router-link :to="{ name: 'NotificationsView' }">
              {{ $t('ui.assets.notifications') }}
              <SvgIcon icon="Next" class="ml-1" />
            </router-link>
            <div class="crypkit-button-text" @click="clearAll">
              {{ $t('ui.common.clear_all') }}
            </div>
          </div>
          <div v-if="notifications.length > 0" class="notifications-container">
            <transition-group name="select-items">
              <div
                v-for="notification in notifications"
                :key="notification.id"
                class="single-notification"
              >
                <div class="single-notification-text text-xs">
                  <span class="block text-xss mb-1">{{
                    formatDateTime(notification.date)
                  }}</span>
                  <div class="text-secondary">
                    <strong>
                      <span class="capitalize">
                        {{ getNotificationType(notification.ref_type) }}:
                      </span>
                      {{ getNotificationName(notification.ref_name) }}
                    </strong>
                  </div>
                  <span
                    class="text-xs whitespace-pre-line"
                    :class="{ 'font-extrabold': !notification.read }"
                  >
                    {{ notification.message }}
                  </span>
                </div>
                <div class="close-icon" @click.stop="markAsDone(notification)">
                  <SvgIcon icon="Close" />
                </div>
              </div>
            </transition-group>
          </div>

          <div v-if="notifications.length < total" class="text-center mt-2">
            <div
              class="crypkit-button-text button-loadmore"
              @click.stop="loadMore"
            >
              {{ $t('ui.common.load_more') }}
            </div>
          </div>

          <div
            v-if="total === 0"
            class="notifications-container text-secondary"
          >
            {{ $t('ui.common.no_notifications') }}
          </div>
        </div>
      </template>
    </Collapsible>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useGlobalStore } from '@/stores/global'
import { useNotificationsStore } from '@/stores/notifications'
import { useUserStore } from '@/stores/user'

import Collapsible from '@/components/controls/Collapsible'
import SvgIcon from '@/components/misc/SvgIcon'

import {
  type Notification,
  type NotificationRefName,
} from '@/types/services/notifications'
import { formatDateHelper } from '@/services/date.service'

export default defineComponent({
  components: {
    Collapsible,
    SvgIcon,
  },

  data() {
    return {
      notificationsStore: useNotificationsStore(),
    }
  },

  computed: {
    ...mapState(useGlobalStore, ['blockchains', 'exchanges']),
    ...mapState(useUserStore, {
      userId: (store) => store.user?.id,
      rootId: (store) => store.root.id,
      rootUserId: (store) => store.root_user?.id,
    }),
    ...mapState(useNotificationsStore, ['notifications', 'total']),
    ...mapState(useUserStore, ['entityId', 'stringToEntityTzDate']),
    ...mapState(useUserStore, {
      dateFormat: (store) => store.settings.additional.date_format,
    }),
  },

  created() {
    this.getNotifications()
    setInterval(
      () => {
        // check notification every 5 minute
        this.getNotifications()
      },
      5 * 60 * 1000
    )
  },

  mounted() {
    this.$bus.on('remindersChanged', this.getNotifications)
  },

  beforeUnmount() {
    this.$bus.off('remindersChanged', this.getNotifications)
  },

  methods: {
    async getNotifications(offset = 0) {
      if (!this.userId || !this.rootUserId) {
        return
      }

      try {
        await this.notificationsStore.getNotifications({
          userId: this.userId,
          rootId: this.rootId,
          rootUserId: this.rootUserId,
          offset,
        })
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
    loadMore() {
      this.getNotifications(this.notifications.length)
    },
    async markAsDone(notification: Notification) {
      try {
        await this.notificationsStore.setDoneNotification(notification.id)
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
    async markAsRead() {
      const ids = this.notifications
        .filter((notification) => !notification.read)
        .map((notification) => notification.id)

      if (ids.length) {
        try {
          await this.notificationsStore.setReadNotifications(ids)
        } catch (err) {
          this.$bus.emit('error', err)
        }
      }
    },
    async clearAll() {
      if (!this.userId || !this.rootUserId) {
        return
      }

      try {
        await this.notificationsStore.setDoneAllNotifications({
          userId: this.userId,
          rootId: this.rootId,
          rootUserId: this.rootUserId,
        })
      } catch (err) {
        this.$bus.emit('error', err)
      }
    },
    getNotificationType(value: string) {
      if (value == 'root_user') {
        return this.$t('ui.common.custom_reminder')
      }
      return value.replace(/_/g, ' ')
    },
    getNotificationName(value: NotificationRefName | null | undefined) {
      if (value === null || typeof value === 'undefined') {
        return ''
      }

      if (value.name == '') {
        if (typeof value.currency_id !== 'undefined') {
          const blockchain = this.blockchains.find(
            (b) => b.currency_id == value.currency_id
          )
          return blockchain ? blockchain.name : this.$t('ui.common.unknown')
        } else if (typeof value.exchange_id !== 'undefined') {
          const exchange = this.exchanges.find((e) => e.id == value.exchange_id)
          return exchange ? exchange.name : this.$t('ui.common.unknown')
        } else {
          return this.$t('ui.common.no_name')
        }
      } else {
        return value.name
      }
    },
    formatDateTime(value: string) {
      const date = this.entityId
        ? this.stringToEntityTzDate(value, this.entityId)
        : value
      return formatDateHelper(date, `${this.dateFormat} H:mm:ss`)
    },
  },
})
</script>
