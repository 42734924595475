import { acceptHMRUpdate, defineStore } from 'pinia'
import { isBefore, parseISO } from 'date-fns'

import { HttpError } from '@/helpers/errors'
import GeneralNotifications from '@/services/notifications/general_notification'
import NotificationsService from '@/services/user/notifications'

import {
  type AssetNotification,
  type AssetNotificationParams,
  type GeneralNotification,
  type GeneralNotificationParams,
  GeneralNotificationType,
  type PortfolioAssetPriceChangeNotification,
  type PortfolioAssetPriceChangeParams,
  type ReminderNotification,
  type ReminderNotificationParams,
} from '@/types/services/general_notifications'
import type { Notification } from '@/types/services/notifications'

export interface NotificationsState {
  notifications: Notification[]
  total: number
  generalNotifications: {
    data: GeneralNotification[]
    loading: boolean
  }
}

export const useNotificationsStore = defineStore('notifications', {
  state(): NotificationsState {
    return {
      notifications: [],
      total: 0,
      generalNotifications: {
        data: [],
        loading: true,
      },
    }
  },

  actions: {
    async getNotifications({
      userId,
      rootId,
      rootUserId,
      offset,
      limit,
    }: {
      userId: string
      rootId: string
      rootUserId: string
      offset?: number
      limit?: number
    }) {
      try {
        const response = await NotificationsService.getNotifications(
          userId,
          rootId,
          rootUserId,
          offset || 0,
          limit || 10
        )
        const now = new Date()
        const data = response.data.data
        let notifications = data.data.filter((item) => {
          return isBefore(parseISO(item.date), now)
        })
        const filteredCount = data.data.length - notifications.length

        if (offset && offset > 0) {
          notifications = this.notifications.concat(notifications)
        }

        this.$patch({
          notifications,
          total: data.total - filteredCount,
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async setDoneNotification(notificationId: string) {
      try {
        await NotificationsService.setDoneNotification(notificationId)

        const idx = this.notifications.findIndex((n) => n.id == notificationId)
        if (idx != -1) {
          this.notifications.splice(idx, 1)
        }
        this.total -= 1
      } catch (err) {
        throw new HttpError(err)
      }
    },

    setReadNotifications(notificationIds: string[]) {
      return new Promise((resolve, reject) => {
        notificationIds.forEach((notificationId) => {
          NotificationsService.setReadNotification(notificationId)
            .then(() => {
              const notification = this.notifications.find(
                (notification) => notification.id == notificationId
              )
              if (notification) {
                notification.read = true
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
        resolve(true)
      })
    },

    async setDoneAllNotifications({
      userId,
      rootId,
      rootUserId,
    }: {
      userId: string
      rootId: string
      rootUserId: string
    }) {
      try {
        await NotificationsService.setDoneAllNotifications(
          userId,
          rootId,
          rootUserId
        )
        this.$patch({
          notifications: [],
          total: 0,
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async getGeneralNotifications(entity_id: string) {
      this.generalNotifications = {
        data: [],
        loading: true,
      }

      try {
        const response = await GeneralNotifications.get(entity_id)
        this.generalNotifications = {
          data: response.data.data.sort((a, b) => {
            return b.created.localeCompare(a.created)
          }),
          loading: false,
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async createGeneralNotification(params: GeneralNotificationParams) {
      try {
        const response = await GeneralNotifications.create(params)
        if (response.data.result.success) {
          this.generalNotifications.data.unshift(response.data.data)
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async updateGeneralNotification(
      id: string,
      params: GeneralNotificationParams
    ) {
      try {
        const response = await GeneralNotifications.update(id, params)
        if (response.data.result.success) {
          const idx = this.generalNotifications.data.findIndex(
            (n) => n.id == id
          )
          if (idx != -1) {
            Object.assign(
              this.generalNotifications.data[idx],
              response.data.data
            )
          }
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async deleteGeneralNotification(id: string) {
      try {
        const response = await GeneralNotifications.remove(id)
        if (response.data.result.success) {
          const idx = this.generalNotifications.data.findIndex(
            (n) => n.id == id
          )
          if (idx != -1) {
            this.generalNotifications.data.splice(idx, 1)
          }
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async createPortfolioNotification(params: PortfolioAssetPriceChangeParams) {
      await this.createGeneralNotification(params)
    },

    async updatePortfolioNotification(
      id: string,
      params: PortfolioAssetPriceChangeParams
    ) {
      await this.updateGeneralNotification(id, params)
    },

    async createAssetNotification(params: AssetNotificationParams) {
      await this.createGeneralNotification(params)
    },

    async updateAssetNotification(id: string, params: AssetNotificationParams) {
      await this.updateGeneralNotification(id, params)
    },

    async createReminderNotification(params: ReminderNotificationParams) {
      await this.createGeneralNotification(params)
    },

    async updateReminderNotification(
      id: string,
      params: ReminderNotificationParams
    ) {
      await this.updateGeneralNotification(id, params)
    },
  },

  getters: {
    portfolioNotifications(state): {
      data: PortfolioAssetPriceChangeNotification[]
      loading: boolean
    } {
      return {
        data: state.generalNotifications.data.filter(
          (n) => n.type == GeneralNotificationType.PORTFOLIO_ASSET_PRICE_CHANGE
        ),
        loading: state.generalNotifications.loading,
      }
    },
    assetNotifications(state): {
      data: (AssetNotification | ReminderNotification)[]
      loading: boolean
    } {
      return {
        data: state.generalNotifications.data.filter(
          (n) =>
            GeneralNotifications.isAssetNotification(n) ||
            GeneralNotifications.isReminderNotification(n)
        ) as (AssetNotification | ReminderNotification)[],
        loading: state.generalNotifications.loading,
      }
    },
    assetNotificationsByCurrencyId(state) {
      return (
        currency_id: string
      ): {
        data: (AssetNotification | ReminderNotification)[]
        loading: boolean
      } => {
        return {
          data: state.generalNotifications.data.filter(
            (n) =>
              (GeneralNotifications.isAssetNotification(n) ||
                GeneralNotifications.isReminderNotification(n)) &&
              (n.data.currency_id == currency_id ||
                n.data.ico_id == currency_id)
          ) as (AssetNotification | ReminderNotification)[],
          loading: state.generalNotifications.loading,
        }
      }
    },
    reminderNotifications(state): {
      data: ReminderNotification[]
      loading: boolean
    } {
      return {
        data: state.generalNotifications.data.filter(
          (n) =>
            GeneralNotifications.isReminderNotification(n) &&
            !n.data.currency_id &&
            !n.data.ico_id
        ) as ReminderNotification[],
        loading: state.generalNotifications.loading,
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationsStore, import.meta.hot)
  )
}
