import type { ResponseResult } from './common'
import type { ValuationMethodType } from './global'

export enum GeneralNotificationType {
  ASSET_FDV_CHANGE = 'asset_fdv_change',
  ASSET_PRICE_CHANGE = 'asset_price_change',
  ENTITY_BALANCE_CHANGE = 'entity_balance_change',
  PORTFOLIO_ASSET_PRICE_CHANGE = 'portfolio_asset_price_change',
  REMINDER = 'reminder',
}

export type GeneralNotificationFactoryType = 'fe' | 'email' | 'telegram'
export interface GeneralNotificationFactory {
  id?: string
  factory_type: GeneralNotificationFactoryType
  factory_id?: string // only for telegram
}

export interface PortfolioAssetPriceChangeData {
  minimal_share_percent: number
  minimal_change_percent: number
  time_window_days: number
  target_currency_id?: string
  valuation_method?: ValuationMethodType
}

export interface AssetNotificationData {
  currency_id?: string
  ico_id?: string
  base_value: number
  target_value: number
  value_currency_id?: string | null
  note: string
}

export interface ReminderNotificationData {
  note: string
  notify_at: string
  currency_id?: string
  ico_id?: string
}

export interface GeneralNotificationParamsBase {
  entity_id: string
  is_recurring: boolean
  notification_factories?: GeneralNotificationFactory[]
}

export interface PortfolioAssetPriceChangeParams
  extends GeneralNotificationParamsBase {
  type: GeneralNotificationType.PORTFOLIO_ASSET_PRICE_CHANGE
  data: PortfolioAssetPriceChangeData
}

export type AssetNotificationType =
  | GeneralNotificationType.ASSET_PRICE_CHANGE
  | GeneralNotificationType.ASSET_FDV_CHANGE
  | GeneralNotificationType.ENTITY_BALANCE_CHANGE

export interface AssetNotificationParams extends GeneralNotificationParamsBase {
  type: AssetNotificationType
  data: AssetNotificationData
}

export interface ReminderNotificationParams
  extends GeneralNotificationParamsBase {
  type: GeneralNotificationType.REMINDER
  data: ReminderNotificationData
}

export type GeneralNotificationParams =
  | PortfolioAssetPriceChangeParams
  | AssetNotificationParams
  | ReminderNotificationParams

export type AssetNotification = AssetNotificationParams & {
  id: string
  created: string
}

export type PortfolioAssetPriceChangeNotification =
  PortfolioAssetPriceChangeParams & {
    id: string
    created: string
  }

export type ReminderNotification = ReminderNotificationParams & {
  id: string
  created: string
}

export type GeneralNotification = GeneralNotificationParams & {
  id: string
  created: string
}

export interface GeneralNotificationResponse {
  data: GeneralNotification
  result: ResponseResult
}

export interface GeneralNotificationsResponse {
  data: GeneralNotification[]
  result: ResponseResult
}
