import { keyBy } from 'lodash-es'
import { isCurrencyValueArray } from '@/helpers/objects'
import { useUserStore } from '@/stores/user'
import type { CurrencyPrice, CurrencyValue } from '@/types/services/common'
import type { TargetCurrency } from '@/types/services/assets'

export type CurrenciesValues = {
  fiat: number | null
  crypto: number | null
}

export type TargetCurrencyValues = {
  fiat: TargetCurrency | null
  crypto: TargetCurrency | null
}

export function useValues() {
  const userStore = useUserStore()

  function getValueFromCurrencyObject(
    obj: CurrencyValue | CurrencyPrice
  ): number | null {
    if ('value' in obj) {
      return obj.value
    }
    return obj.price
  }

  function findValueByCurrencyId(
    values: CurrencyValue[] | CurrencyPrice[],
    currencyId: string
  ): number | null {
    const value = values.find((v) => v.currency_id === currencyId)
    return value ? getValueFromCurrencyObject(value) : null
  }

  function findFiatValue(values: CurrencyValue[] | CurrencyPrice[]) {
    if (!userStore.currencyFiat) {
      return null
    }
    return findValueByCurrencyId(values, userStore.currencyFiat.id)
  }

  function findCryptoValue(values: CurrencyValue[] | CurrencyPrice[]) {
    if (!userStore.currencyCrypto) {
      return null
    }
    return findValueByCurrencyId(values, userStore.currencyCrypto.id)
  }

  function findCurrenciesValues(values: CurrencyValue[] | CurrencyPrice[]) {
    const valuesMap = keyBy<CurrencyValue | CurrencyPrice>(
      values,
      'currency_id'
    )

    const foundValues: CurrenciesValues = {
      fiat: null,
      crypto: null,
    }

    const fiatId = userStore.currencyFiat?.id
    if (fiatId && fiatId in valuesMap) {
      foundValues.fiat = getValueFromCurrencyObject(valuesMap[fiatId])
    }

    const cryptoId = userStore.currencyCrypto?.id
    if (cryptoId && cryptoId in valuesMap) {
      foundValues.crypto = getValueFromCurrencyObject(valuesMap[cryptoId])
    }

    return foundValues
  }

  function findTargetCurrencies(tc: TargetCurrency[]) {
    const tcMap = keyBy(tc, 'currency_id')

    const foundValues: TargetCurrencyValues = {
      fiat: null,
      crypto: null,
    }

    if (userStore.currencyFiat) {
      foundValues.fiat = tcMap[userStore.currencyFiat.id] ?? null
    }

    if (userStore.currencyCrypto) {
      foundValues.crypto = tcMap[userStore.currencyCrypto.id] ?? null
    }

    return foundValues
  }

  function countObjectPropertySum<T>(
    objects: T[],
    property: keyof T,
    type: 'fiat' | 'crypto'
  ) {
    const findFn = type === 'fiat' ? findFiatValue : findCryptoValue
    let sum = 0

    for (const obj of objects) {
      const value = isCurrencyValueArray(obj[property])
        ? (findFn(obj[property]) ?? 0)
        : 0
      sum += value
    }

    return sum
  }

  function countTotal(
    values: CurrenciesValues,
    amount: number
  ): CurrenciesValues {
    return {
      fiat: values.fiat !== null ? amount * values.fiat : null,
      crypto: values.crypto !== null ? amount * values.crypto : null,
    }
  }

  return {
    findValueByCurrencyId,
    findFiatValue,
    findCryptoValue,
    findCurrenciesValues,
    findTargetCurrencies,
    countObjectPropertySum,
    countTotal,
  }
}
